<template v-if="formActive">
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="invoicenr-tab" data-bs-toggle="tab" data-bs-target="#invoicenr" type="button" role="tab" aria-controls="invoicenr" aria-selected="true" @click="setNextButton(1, true)">{{`1 - ${$t('navigation.quotations')} & ${$t('navigation.invoices')}` }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance_letterhead-tab" data-bs-toggle="tab" data-bs-target="#finance_letterhead" type="button" role="tab" aria-controls="finance_letterhead" @click="setNextButton(2, true)">{{'2 - ' + $tc('accounting.finance_letterhead', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance_banks-tab" data-bs-toggle="tab" data-bs-target="#finance_banks" type="button" role="tab" aria-controls="finance_banks" @click="setNextButton(2, true)">{{'3 - ' + $tc('accounting.finance_banks', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance_bank_accounts-tab" data-bs-toggle="tab" data-bs-target="#finance_bank_accounts" type="button" role="tab" aria-controls="finance_bank_accounts" @click="setNextButton(3, true)">{{'4 - ' + $tc('accounting.finance_bank_accounts', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance_gla_accounts-tab" data-bs-toggle="tab" data-bs-target="#finance_gla_accounts" type="button" role="tab" aria-controls="finance_gla_accounts" @click="setNextButton(4, true)">{{'5 - ' + $tc('accounting.finance_gla_accounts', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance_articles-tab" data-bs-toggle="tab" data-bs-target="#finance_articles" type="button" role="tab" aria-controls="finance_articles" @click="setNextButton(5, true)">{{'6 - ' + $tc('accounting.finance_articles', 2)}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="accounting-tab" data-bs-toggle="tab" data-bs-target="#accounting" type="button" role="tab" aria-controls="accounting" @click="setNextButton(6, true)">{{'7 - ' + $t('navigation.accounting')}}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="invoicenr" role="tabpanel" aria-labelledby="invoicenr-tab">
        <h4>{{$t('navigation.quotations')}}</h4>
        <div class="row">
            <div class="col-3 required-label">{{$t('accounting.use_bl_terms')}}</div>
            <div class="col-9">
                <FormItem id="managed_by_relations" :label="$t('accounting.terms_conditions_label')" v-model="finance.use_bl_terms" type="checkbox" />
            </div>
        </div>
        <div class="row" v-show="!finance.use_bl_terms">
            <div class="col-4 required-label">{{$t('sales.terms_3')}}</div>
            <div class="col-8">
                <div v-if="finance.terms_and_conditions_file_info" class="mb-1">
                    <a class="col-5" href="#">{{finance.terms_and_conditions_file_info[0].name}}</a>
                </div>
                <div v-else-if="finance.terms_and_conditions_file" class="mb-1">
                    <a class="col-5" href="#" @click="download(finance.terms_and_conditions_file)">{{finance.terms_and_conditions_file.name}}</a>
                </div>
                <input type="file" @change="uploadFile($event, finance, 'terms_and_conditions_file_info', 'terms_and_conditions_file_id', true)" />
            </div>
        </div>
        <h4 class="mt-4">{{$t('navigation.invoices')}}</h4>
        <p>
            {{$t('accounting.invoice_number_desc_1')}}<br />
            {{$t('accounting.invoice_number_desc_2')}} 
        </p>
        <div class="d-flex">
            <div class="w-25 required-label">{{$t('accounting.invoice_number')}}</div>
            <FormItem id="inr-1" v-model="finance.country_code_invoice_number" :disabled="true" :style="{'width': '60px', 'marginRight': '1rem'}" />
            <FormItem id="inr-2" v-model="finance.office_code_invoice_number" :required="true" :style="{'width': '60px', 'marginRight': '1rem'}" @change="setOfficeNumbertwoDigits" />
            <FormItem id="inr-3" v-model="defaultSeparator" :disabled="true" :style="{'width': '40px', 'marginRight': '1rem'}" />
            <FormItem id="inr-4" v-model="defaultNumber" :disabled="true" :style="{'width': '120px'}" />
        </div>
        <p class="mt-4">
            {{$t('accounting.invoice_set_email_1')}}<br />
            {{$t('accounting.invoice_set_email_2')}}
        </p>
        <FormItem id="inr-5" :label="$t('accounting.invoice_set_email_3')" v-model="finance.invoice_email" :placeholder="email" sizeLabel="3" sizeItem="4" />
        <FormItem id="inr-6" :label="$t('accounting.invoice_set_email_4')" v-model="finance.invoice_email_template" type="textarea" sizeLabel="3" sizeItem="9" options="10" @onblur="setCaret" />
        <div style="padding-left:25%" id="template-vars">Template variables: 
            <span @click="addToTemplate">#CUSTOMER_NAME#</span>&ensp;
            <span @click="addToTemplate">#COMPANY_NAME#</span>&ensp;
            <span @click="addToTemplate">#INVOICE_NUMBER#</span>
        </div>
        <h4>{{$tc('accounting.project_number', 2)}}</h4>
        <FormItem id="stena" label="Stena Nr." v-model="finance.stena_number" sizeItem="4" sizeLabel="3" type="text" />
    </div>
    <div class="tab-pane fade" id="finance_letterhead" role="tabpanel" aria-labelledby="finance_letterhead-tab">
        <h4>{{$t('accounting.finance_letterhead')}}</h4>

        <FormItem id="header_styling" :label="$t('accounting.header_styling')" v-model="finance.header_styling" type="select" :options="headerStylingOptions" />
        
        <div class="row mb-2">
            <label for="footer_logo" class="col-form-label col-form-label-sm col-sm-4">{{$t('accounting.footer_logo')}}</label>
            <div class="col-sm-8">
                <div v-if="finance.footer_logo_file_info" class="mb-1">
                    <a class="col-5" href="#">{{finance.footer_logo_file_info[0].name}}</a>&ensp;<i class="btn p-0 fa fa-close ps-33 text-danger" @click="removeLogo"></i>
                </div>
                <div v-else-if="finance.footer_logo_file" class="mb-1">
                    <a class="col-5" href="#" @click="download(finance.footer_logo_file)">{{finance.footer_logo_file.name}}</a>&ensp;<i class="btn p-0 fa fa-close ps-33 text-danger" @click="removeLogo"></i>
                </div>
                <input type="file" @change="uploadFile($event, finance, 'footer_logo_file_info', 'footer_logo_file_id')" />
                
            </div>
        </div>

        <FormItem id="footer_text" :label="$t('accounting.footer_text')" v-model="finance.footer_text" type="textarea" />
        <FormItem id="footer_subtext" :label="$t('accounting.footer_subtext')" v-model="finance.footer_subtext" />
    </div>
    <div class="tab-pane fade" id="finance_banks" role="tabpanel" aria-labelledby="finance_banks-tab">
        <div class="accordion" :id="tabs[1] + '-accordion'">
            <div v-for="(item, index) in finance.finance_banks" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button :class="'acc-header-item header-main accordion-button ' + (item.id ? 'collapsed' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+tabs[1]+'-collapse-'+index" 
                            :aria-expanded="(item.id ? 'false' : 'true')" :aria-controls="'#'+tabs[1]+'-collapse-'+index">
                        <div class="d-flex w-100">
                            <template v-if="item.name">
                                <div class="col-4">
                                    {{item.name}}
                                </div>
                                <div class="col-4">
                                    {{safeFind(countries, 'id', item.country_id, 'name')}}
                                </div>
                                <div class="col-4">
                                    {{item.SWIFT_code}}
                                </div>
                            </template>
                            <div class="col-4" v-else>
                                {{$t('sales.new_x', [$tc('accounting.' + tabs[1], 1)])}}
                            </div>
                        </div>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="deleteItem(index, 0)"><i class="fal fa-trash-alt"></i></button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div :class="'accordion-collapse collapse ' + (item.id ? '' : 'show')" :id="tabs[1] + '-collapse-'+index" :aria-labelledby="'heading-'+index" :data-bs-parent="'#'+tabs[1]+'-accordion'">
                    <div class="accordion-body">
                        <FormItem :label="$t('overview.name')" v-model="item.name" :required="true" />
                        <FormItem :label="$t('navigation.addresses')" v-model="item.address" :required="true" type="textarea" />
                        <FormItem :label="$t('navigation.countries')" v-model="item.country_id" type="livesearch" :options="countries" :content="safeFind(countries, 'id', item.country_id, 'name')" :required="true" />
                        <FormItem label="SWIFT code" v-model="item.SWIFT_code" :required="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addItem(0)">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('accounting.' + tabs[1], 1)])}}
            </a>
        </div>
        
    </div>
    <div class="tab-pane fade" id="finance_bank_accounts" role="tabpanel" aria-labelledby="finance_bank_accounts-tab">

        <div class="accordion" :id="tabs[2] + '-accordion'">
            <div v-for="(item, index) in finance.finance_bank_accounts" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button :class="'acc-header-item header-main accordion-button ' + (item.id ? 'collapsed' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+tabs[2]+'-collapse-'+index" 
                            :aria-expanded="(item.id ? 'false' : 'true')" :aria-controls="'#'+tabs[2]+'-collapse-'+index">
                        <div class="d-flex w-100">
                            <template v-if="item.finance_bank_id">
                                <div class="col-4">
                                    {{safeFind(finance.finance_banks, 'id', item.finance_bank_id, 'name')}}
                                </div>
                                <div class="col-6">
                                    {{item.IBAN}}
                                </div>
                                <div class="col-2">
                                    {{safeFind(currencies, 'id', item.currency_id_display, 'code')}}
                                </div>
                            </template>
                            <div class="col-4" v-else>
                                {{$t('sales.new_x', [$tc('accounting.' + tabs[2], 1)])}}
                            </div>
                        </div>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="deleteItem(index, 1)"><i class="fal fa-trash-alt"></i></button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div :class="'accordion-collapse collapse ' + (item.id ? '' : 'show')" :id="tabs[2] + '-collapse-'+index" :aria-labelledby="'heading-'+index" :data-bs-parent="'#'+tabs[2]+'-accordion'">
                    <div class="accordion-body">
                        <FormItem :label="$t('accounting.finance_banks')" v-model="item.finance_bank_id" :required="true" type="select" :options="getFilteredList(finance.finance_banks, 'name')" />
                        <FormItem content="iban" label="IBAN" v-model="item.IBAN" :required="true" @onchange="checkIBAN($event, index)" />
                        <div v-show="invalidIBAN[index]" class="ps-33 text-danger">{{$t('errors.iban')}}</div>
                        <FormItem :label="$t('navigation.currencies')" v-model="item.currency_id_display" :required="true" type="select" :options="currencies" nameField="code" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addItem(1)">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('accounting.' + tabs[2], 1)])}}
            </a>
        </div>
        
    </div>
    <div class="tab-pane fade" id="finance_gla_accounts" role="tabpanel" aria-labelledby="finance_gla_accounts-tab">

        <div class="accordion" :id="tabs[3] + '-accordion'">
            <div v-for="(item, index) in finance.finance_gla_accounts" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button :class="'acc-header-item header-main accordion-button ' + (item.id ? 'collapsed' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+tabs[3]+'-collapse-'+index" 
                            :aria-expanded="(item.id ? 'false' : 'true')" :aria-controls="'#'+tabs[3]+'-collapse-'+index" @click.prevent="collapseOthers(index, tabs[3])">
                        <div class="d-flex w-100">
                            <template v-if="item.gla_account_number">
                                <div class="col-4">
                                    {{item.gla_account_number}}
                                </div>
                                <div class="col-8">
                                    {{item.description}}
                                </div>
                            </template>
                            <div class="col-4" v-else>
                                {{$t('sales.new_x', [$tc('accounting.' + tabs[3], 1)])}}
                            </div>
                        </div>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="deleteItem(index, 2)"><i class="fal fa-trash-alt"></i></button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div :class="'accordion-collapse collapse ' + (item.id ? '' : 'show')" :id="tabs[3] + '-collapse-'+index" :aria-labelledby="'heading-'+index" :data-bs-parent="'#'+tabs[3]+'-accordion'">
                    <div class="accordion-body">
                        <FormItem :label="$t('accounting.finance_gla_accounts')" v-model="item.gla_account_number" @onchange="setAccountName(item)" :required="true" />
                        <FormItem :label="$t('form.description')" v-model="item.description" @onchange="setAccountName(item)" :required="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addItem(2)">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('accounting.' + tabs[3], 1)])}}
            </a>
        </div>
        
    </div>
    <div class="tab-pane fade" id="finance_articles" role="tabpanel" aria-labelledby="finance_articles-tab">
        <div class="accordion" :id="tabs[4] + '-accordion'">
            <div v-for="(item, index) in finance.finance_articles" :key="index" class="accordion-item">

                <div class="accordion-header d-flex" :id="'heading-'+index">
                    <button :class="'acc-header-item header-main accordion-button ' + (item.id ? 'collapsed' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+tabs[4]+'-collapse-'+index" 
                            :aria-expanded="(item.id ? 'false' : 'true')" :aria-controls="'#'+tabs[4]+'-collapse-'+index" @click.prevent="collapseOthers(index, tabs[4])">
                        <div class="d-flex w-100">
                            <template v-if="item.number">
                                <div class="col-4">
                                    {{item.number}}
                                </div>
                                <div class="col-8">
                                    {{item.description}}
                                </div>
                            </template>
                            <div class="col-4" v-else>
                                {{$t('sales.new_x', [$tc('accounting.' + tabs[4], 1)])}}
                            </div>
                        </div>
                    </button>
                    <button type="button" class="acc-header-item header-remove-item" @click="deleteItem(index, 3)"><i class="fal fa-trash-alt"></i></button>
                    <button type="button" class="acc-header-item header-arrow" @click="openAccordion"></button>
                </div>

                <div :class="'accordion-collapse collapse ' + (item.id ? '' : 'show')" :id="tabs[4] + '-collapse-'+index" :aria-labelledby="'heading-'+index" :data-bs-parent="'#'+tabs[4]+'-accordion'">
                    <div class="accordion-body">
                        <FormItem :label="$t('overview.number')" v-model="item.number" :required="true" />
                        <FormItem :label="$t('form.description')" v-model="item.description" :required="true" />
                        <FormItem :label="$t('accounting.finance_gla_accounts')" v-model="item.finance_gla_account_id" :required="true" type="select" :options="getFilteredList(finance.finance_gla_accounts, 'gla_account_number')" />
                        <FormItem :label="$t('accounting.vat_percentages')" v-model="item.vat_percentage_id" :required="true" type="select" :options="vatAmounts" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 justify-content-center">
            <a class="btn btn-sm btn-primary w-25" title="Click to add item" @click="addItem(3)">
                <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('accounting.' + tabs[4], 1)])}}
            </a>
        </div>
        
    </div>

    <div class="tab-pane fade" id="accounting" role="tabpanel" aria-labelledby="accounting-tab">
        <FormItem id="acc_destination" :label="$t('accounting.accounting_software')" v-model="finance.company_export_setting.export_destination_id" sizeItem="4" sizeLabel="3" type="select" :options="destinations" />
        
        <div class="d-flex pb-2" v-if="finance.company_export_setting.export_destination_id == 1">
            <label class="col-form-label col-form-label-sm" style="width: calc(25% + 6px)">OAuth</label>
            <FormItem type="download" id="connectbutton" :label="$t('accounting.connect')" :service="connect" :options="{small: true}" startTag="fa fa-circle-nodes" />
            <label class="col-form-label col-form-label-sm">&nbsp;&nbsp;&nbsp;{{finance.company_export_setting.username ? `signed in as user '` + finance.company_export_setting.username + `'` : `not signed in`}}</label>
        </div>
        <div class="d-flex pb-2" v-if="finance.company_export_setting.export_destination_id == 1 && finance.company_export_setting.division_name">
            <label class="col-form-label col-form-label-sm" style="width: calc(25% + 6px)">{{$t('accounting.division')}}</label>
            <label class="col-form-label col-form-label-sm">{{finance.company_export_setting.division_name}}</label>
        </div>
        <FormItem id="acc_division" :label="$t('accounting.division')" v-model="finance.company_export_setting.division" sizeItem="4" sizeLabel="3" v-if="finance.company_export_setting.export_destination_id == 2" />
        <h5 class="mt-4">{{$t('accounting.project_regions')}}</h5>
        <ul class="nav nav-tabs mb-3" id="myTab2" role="tablist">
            <li class="nav-item d-flex" style="height: 2.5rem" role="presentation">
                <button class="nav-link" id="itab" data-bs-toggle="tab" data-bs-target="#itarget" type="button" role="tab" aria-controls="itab" aria-selected="true">
                    {{ (finance.default_project_name ? finance.default_project_name : "Default (no region)")}}
                </button>
            </li>
            <li class="nav-item d-flex" style="height: 2.5rem" role="presentation" v-for="(setting, sIndex) in finance.project_regions" :key="setting.uuid">
                <button class="nav-link" :id="'itab-'+setting.uuid" data-bs-toggle="tab" :data-bs-target="'#itarget-'+setting.uuid" type="button" role="tab" :aria-controls="'itab-'+setting.uuid" aria-selected="true" :class="{'text-danger': (setting.name === '')}">
                    {{ (setting.name ? setting.name : $t('form.new')) }}
                </button>
                <a class="btn btn-sm pt-2 ps-0" title="Click to delete" @click="removeExportTab(sIndex, setting)"> <span class="fa fa-close ps-33 text-danger"></span> </a>
            </li>
            <li class="pt-1 ps-2">
                <a class="btn btn-sm btn-primary" title="Click to add item" @click="addExportTab()"> <span class="fa fa-plus"></span> </a>
            </li>
        </ul>
        <div class="tab-content" id="myTab2Content">
        <div class="tab-pane fade" id="itarget" role="tabpanel" aria-labelledby="itab">
            <FormItem id="acc_name" :label="$t('overview.name')" v-model="finance.default_project_name" sizeItem="4" sizeLabel="3" type="text" :required="true" placeholder="Default (no region)" />
            <FormItem id="acc_project_number" :label="$t('accounting.project_number')" v-model="finance.company_export_setting.project_number" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem id="acc_dagboek" :label="$t('accounting.acc_dagboek')" v-model="finance.company_export_setting.journal" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem id="acc_grootbr" :label="$t('accounting.acc_grootbr')" v-model="finance.company_export_setting.default_glaccount" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem id="acc_grootbr4" :label="$t('accounting.acc_grootbr') + ' - ' + $t('accounting.vat_zero')" v-model="finance.company_export_setting.no_vat_glaccount" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem id="acc_grootbr4" :label="$t('accounting.acc_grootbr') + ' - ' + $t('accounting.vat_shifted')" v-model="finance.company_export_setting.vat_shifted_glaccount" sizeItem="4" sizeLabel="3" type="text" />
        </div>
        <div class="tab-pane fade" v-for="(setting) in finance.project_regions" :key="setting.uuid" :id="'itarget-'+setting.uuid" role="tabpanel" :aria-labelledby="'itab-'+setting.uuid">
            <FormItem :label="$t('overview.name')" v-model="setting.name" sizeItem="4" sizeLabel="3" type="text" :required="true" />
            <FormItem :label="$t('accounting.project_number')" v-model="setting.project_number" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem :label="$t('accounting.acc_dagboek')" v-model="setting.journal" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem :label="$t('accounting.acc_grootbr')" v-model="setting.default_glaccount" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem :label="$t('accounting.acc_grootbr') + ' - ' + $t('accounting.vat_zero')" v-model="setting.no_vat_glaccount" sizeItem="4" sizeLabel="3" type="text" />
            <FormItem :label="$t('accounting.acc_grootbr') + ' - ' + $t('accounting.vat_shifted')" v-model="setting.vat_shifted_glaccount" sizeItem="4" sizeLabel="3" type="text" />
        </div>
        </div>
        <h5 class="mt-4">{{$tc('accounting.vat_percentages', 2)}}</h5>
        <template v-for="vatAmount in vatAmounts" :key="vatAmount.id">
            <FormItem :id="'vat_'+vatAmount.id" :label="vatAmount.name" v-model="vatAmount.code" sizeItem="4" sizeLabel="3" type="text" />
        </template>
        <h5 class="mt-4">{{$tc('navigation.payment_terms', 2)}}</h5>
        <template v-for="term in paymentTerms" :key="term.id">
            <FormItem :id="'pt_'+term.id" :label="term.name" v-model="term.code" sizeItem="4" sizeLabel="3" type="text" />
        </template>
    </div>
    </div>
</template>

<script>
    import currencyService from "@/services/CurrencyService";
    import relationService from "@/services/RelationService";
    import officeService from '@/services/FinanceSettingService';
    import paymentTermService from '@/services/PaymentTermService';
    import fileService from "@/services/FileService";
    import dataStore from '@/store/data'
    import moment from 'moment';
    import IBAN from 'iban';
    export default {
        name: 'accountsForm',
        props: ['itemData'],
        data() {
            return {
                finance: {finance_banks: [], finance_bank_accounts: [], finance_gla_accounts: [], finance_articles: [], company_export_setting: {}, project_regions: []},
                id: 0,
                countries: [],
                currencies: [],
                vatAmounts: [],
                paymentTerms: [],
                requiredOptions: [],
                destinations: [],
                exactonlineLanguages: [{'id': 'NL', 'name': 'NL'}], 
                defaultNumber: moment().format('YY') + '*****',
                defaultSeparator: '-',
                tabs: ['invoicenr', 'finance_banks', 'finance_bank_accounts', 'finance_gla_accounts', 'finance_articles'],
                savebutton: null,
                isEditing: false,
                submitted: false,
                loading: false,
                autoSaveTab: false,
                invalidIBAN: {},
                email: '',
                caretStart: null,
                caretEnd: null,
                dataItemsLoaded: 0,
                dataItemsToLoad: 3,
                headerStylingOptions: [{id: 'nmt', name: 'NMT Shipping'}],
                standardTemplate: "Dear #CUSTOMER_NAME#,\n\nPlease see attached invoice #INVOICE_NUMBER# from #COMPANY_NAME#.\nDon’t hesitate to reach out if you have any questions.\n\nKind regards,\n\n#COMPANY_NAME#\nAccounting Department",
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.finance = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;

                if(!this.finance.office_code_invoice_number){
                    document.getElementById('invoicenr-tab').click();
                    document.getElementById('inr-2').classList.add('is-invalid');
                    alert(this.$t('errors.empty_fields'));
                    return false;
                }

                const tabSize = this.tabs.length;
                for(let tabIndex = 1; tabIndex < tabSize; tabIndex++) {
                    const tab = this.tabs[tabIndex];
                    valid = this.validateAccordion(null, tab, true);
                    if(!valid){
                        alert(this.$t('errors.empty_fields'));
                        document.getElementById(tab + '-tab').click();
                        return false;
                    }
                }
                if(!valid) return false;
                let required = document.querySelectorAll("#accounting input");
                for(let field of required){
                    if((field.required || field.classList.contains('required')) && (!field.value)){
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else {
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid){
                    alert(this.$t('errors.empty_fields'));
                    document.getElementById('accounting-tab').click();
                    return false;
                }
                this.finance.invoice_number = '' + this.finance.country_code_invoice_number + this.finance.office_code_invoice_number;
                if(!this.isEditing){
                    officeService.store(this.finance).then(response => {
                        this.$parent.close();
                    }).catch(error => {
                        console.log('error', error);
                    });
                    return false;
                }
                this.finance.finance_bank_accounts.forEach(account => {
                    account.currency_id = account.currency_id_display === -1 ? null : account.currency_id_display;
                });
                if(valid){
                    this.finance.finance_vat_percentage_codes = this.vatAmounts;
                    this.finance.finance_payment_term_codes = this.paymentTerms;
                }
                return valid;
            },
            getData() {
                console.log("Getting data to sent", this.finance);
                return this.finance;
            },
            setData(data) {
                if(data){
                    console.log(data);
                    if(this.savebutton) this.savebutton.disabled = true;
                    this.isEditing = true;
                    this.finance = {finance_banks: [], finance_bank_accounts: [], finance_gla_accounts: [], finance_articles: [], relation: {}, company_export_setting: {}, project_regions: []};
                    this.id = this.isEditing ? data.office_id : data.id;
                    this.email = (this.isEditing && data.invoice_email) ? data.invoice_email : (data.office.relation.addresses[0] ? data.office.relation.addresses[0].email : '');

                    this.$nextTick(() => {
                        if(this.isEditing){
                            this.finance = data;
                            this.finance.relation = data.office.relation;
                            this.finance.stena_number = data.office.stena_number;
                            this.finance.terms_and_conditions_file_info = null;
                            this.finance.footer_logo_file_info = null;
                            this.finance.finance_bank_accounts.forEach(account => {
                                account.currency_id_display = account.currency_id === null ? -1 : account.currency_id;
                            });
                            this.finance.finance_gla_accounts.forEach(account => {
                                account.name = account.gla_account_number + ' - ' + account.description;
                            });
                            if(!this.finance.office_code_invoice_number){
                                this.finance.office_code_invoice_number = this.id > 9 ? (this.id % 100) : ('0' + this.id);
                            }
                            if(!this.finance.invoice_email_template){
                                this.finance.invoice_email_template = this.standardTemplate
                            }
                            if(!this.finance.company_export_setting){
                                this.finance.company_export_setting = {
                                    "journal": null,
                                    "country": "XX",
                                    "default_glaccount": null,
                                    "vat_shifted_glaccount": null,
                                    "no_vat_glaccount": null,
                                    "project_number": 1,
                                    "is_active": 1,
                                    "export_destination_id": 1
                                };
                            }
                            if(!this.finance.project_regions){
                                this.finance.project_regions = [];
                            }
                            this.finance.project_regions.forEach(setting => setting.uuid = (setting.id + 1));
                        }
                        else{
                            this.finance = {
                                'office_id': this.id,
                                'finance_articles': [],
                                'finance_banks': [],
                                'finance_bank_accounts': [],
                                'finance_gla_accounts': [],
                                'country_code_invoice_number': 'XX',
                                'office_code_invoice_number': this.id > 9 ? (this.id % 100) : ('0' + this.id),
                                'relation': data.office.relation,
                                'use_bl_terms': false,
                                'invoice_email': '',
                                'stena_number': '',
                                'invoice_email_template': this.standardTemplate,
                                'default_project_name': "Default",
                                'finance_vat_percentage_codes': [],
                                'finance_payment_term_codes': [],
                                "project_regions": [],
                                "company_export_setting": [{
                                    "journal": null,
                                    "country": "XX",
                                    "default_glaccount": null,
                                    "vat_shifted_glaccount": null,
                                    "no_vat_glaccount": null,
                                    "project_number": 1,
                                    "is_active": 1,
                                    "export_destination_id": 1,
                                    "uuid": 1
                                }]
                            };
                        }
                    });
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }

                this.$nextTick(() => {
                    if(!this.isEditing || this.finance.country_code_invoice_number == null){
                        this.finance.country_code_invoice_number = this.safeFind(this.countries, 'id', this.finance.relation.country_id, 'iso');
                    }
                    this.finance.company_export_setting.country = this.finance.country_code_invoice_number;
                    if(this.savebutton) this.savebutton.disabled = false;
                    document.getElementById("invoicenr-tab").click();
                });

                this.autoSaveTab = false;
                this.dataItemsLoaded = 0;

            },
            reset() {
                return;
            },
            loadData(){
                this.countries = dataStore.getters.getCountryNames;
                currencyService.index().then(response => {
                    this.currencies = response.data;
                    this.currencies.splice(0, 0, {'id': -1, 'code': this.$t('accounting.no_specific_currency')});
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                });
                officeService.getDestinations().then(response => {
                    this.destinations = response.data;
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                });
                relationService.getVatPercentages(this.id).then(response => {
                    if(response.data){
                        this.vatAmounts = [];
                        response.data.forEach(vat => {
                            const financeVat = this.finance.finance_vat_percentage_codes.find(vc => vc.vat_percentage_id == vat.id) || {};
                            this.vatAmounts.push({id: vat.id, name: (vat.percentage + '%'), code: financeVat.code || null, fvat_id: financeVat.id || null});
                        });
                    }
                    console.log(this.vatAmounts);
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    this.loading = false;
                    console.log('error', error)
                });
                paymentTermService.index().then(response => {
                    this.paymentTerms = response.data;
                    this.paymentTerms.forEach(term => {
                        const financeTerm = this.finance.finance_payment_term_codes.find(pt => pt.payment_term_id == term.id) || {};
                        term.name = term.days == 0 ? 'Payment Against Documents' : (term.days + ' days');
                        term.code = financeTerm.code || null;
                        term.fpt_id = financeTerm.id || null;
                    });
                    console.log(this.paymentTerms);
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                });
            },
            setAccountName(account){
                account.name = account.gla_account_number + ' - ' + account.description;
            },
            addExportTab(){
                let highest = this.finance.company_export_setting.project_number ? Number(this.finance.company_export_setting.project_number) : 1
                this.finance.project_regions.forEach((s) => {
                    if (s.uuid > highest) highest = s.uuid;
                });
                highest++;
                this.finance.project_regions.push({
                    "journal": null,
                    "country": this.finance.country_code_invoice_number,
                    "default_glaccount": null,
                    "vat_shifted_glaccount": null,
                    "no_vat_glaccount": null,
                    "project_number": this.finance.project_regions.length + 2,
                    "is_active": 1,
                    "export_destination_id": 1,
                    "uuid": highest,
                    "name": ""
                });
                this.$nextTick(() => document.getElementById('itab-'+highest).click());
            },
            removeExportTab(index, setting){
                if(confirm(this.$t('form.are_you_sure_delete') + ' ' + (setting.name || this.$t('form.new')) + '?')){
                    this.finance.project_regions.splice(index, 1);
                    this.$nextTick(() => document.getElementById("itab").click());
                }
            },
            connect(){
                if(!this.validate()){
                    return false;
                }
                const item = {...this.finance};
                item.should_connect = true;
                if(item.id) {
                    return officeService.update(item).then((response) => {
                        window.location.replace(response.data.url);
                    }).catch(error => {
                        this.toastError(error)
                        console.log('error', error) // create notification-toaster for user error
                    })
                } else {
                    return officeService.store(item).then((response) => {
                        window.location.replace(response.data.url);
                    }).catch(error => {
                        this.toastError(error)
                        console.log('error', error) // create notification-toaster for user error
                    })
                }
            },
            autoSave(){
                if(this.autoSaveTab === false) return;
                let dataToSend = {};
                let shouldSend = false;
                if(this.autoSaveTab === 1){
                    dataToSend.finance_banks = [];
                    this.finance.finance_banks.forEach((item, index) => {
                        if(!item.id && this.validateAccordion(index, 'finance_banks', false)){
                            dataToSend.finance_banks.push(item);
                            shouldSend = true;
                        }
                    });
                }
                if(this.autoSaveTab === 3){
                    dataToSend.finance_gla_accounts = [];
                    this.finance.finance_gla_accounts.forEach((item, index) => {
                        if(!item.id && this.validateAccordion(index, 'finance_gla_accounts', false)){
                            dataToSend.finance_gla_accounts.push(item);
                            shouldSend = true;
                        }
                    });
                }
                if(!shouldSend){
                    return;
                }
                
                dataToSend.do_not_delete = true;
                dataToSend.id = this.finance.id;
                dataToSend.office_id = this.finance.office_id;
                dataToSend.office_code_invoice_number = this.finance.office_code_invoice_number;
                dataToSend.country_code_invoice_number = this.finance.country_code_invoice_number;
                dataToSend.use_bl_terms = this.finance.use_bl_terms;
                dataToSend.invoice_email = this.finance.invoice_email;
                if(this.isEditing){
                    officeService.update(dataToSend).then(response => {
                        if(this.autoSaveTab === 1){
                            this.finance.finance_banks = response.data.finance_banks;
                        }
                        else{
                            this.finance.finance_gla_accounts = response.data.finance_gla_accounts;
                            this.finance.finance_gla_accounts.forEach(account => {
                                account.name = account.gla_account_number + ' - ' + account.description;
                            });
                        }
                        this.autoSaveTab = false;
                    }).catch(error => {
                        console.log('error', error);
                    });
                }
                else{
                    officeService.store(dataToSend).then(response => {
                        if(this.autoSaveTab === 1){
                            this.finance.finance_banks = response.data.finance_banks;
                        }
                        else{
                            this.finance.finance_gla_accounts = response.data.finance_gla_accounts;
                            this.finance.finance_gla_accounts.forEach(account => {
                                account.name = account.gla_account_number + ' - ' + account.description;
                            });
                        }
                        this.isEditing = true;
                        this.autoSaveTab = false;
                    }).catch(error => {
                        console.log('error', error);
                    });
                }
            },
            addItem(mode){
                let array = [];
                let itemToAdd = {};
                if(mode == 0){
                    array = this.finance.finance_banks;
                    itemToAdd = {
                        'name': '',
                        'address': '',
                        'country_id': null,
                        'SWIFT_code':''
                    };
                    this.autoSaveTab = 1;
                }
                if(mode == 1){
                    array = this.finance.finance_bank_accounts;
                    itemToAdd = {
                        'finance_bank_id': null,
                        'IBAN': '',
                        'currency_id': null,
                        'currency_id_display': null
                    };
                }
                if(mode == 2){
                    array = this.finance.finance_gla_accounts;
                    itemToAdd = {
                        'gla_account_number': '',
                        'description': ''
                    };
                    this.autoSaveTab = 3;
                }
                if(mode == 3){
                    array = this.finance.finance_articles;
                    itemToAdd = {
                        'number': '',
                        'description': '',
                        'finance_gla_account_id': null,
                        'vat_percentage_id': null
                    };
                }
                this.collapseOthers(-1, this.tabs[mode + 1]);
                array.push(itemToAdd);
            },
            deleteItem(index, mode){
                let array = [];
                if(mode == 0) array = this.finance.finance_banks;
                if(mode == 1) array = this.finance.finance_bank_accounts;
                if(mode == 2) array = this.finance.finance_gla_accounts;
                if(mode == 3) array = this.finance.finance_articles;
                array.splice(index, 1);
            },
            setNextButton(tabNumber, autoSave = false){
                if(autoSave){
                    this.autoSave();
                }
                const nextButton = document.getElementById("nextbutton");
                if(tabNumber == 6){
                    nextButton.classList.add('d-none');
                    this.$nextTick(() => document.getElementById("myTab2").firstElementChild.firstElementChild.click());
                }
                else
                    nextButton.classList.remove('d-none');
                nextButton.lastElementChild.innerHTML = (tabNumber + 1);
            },
            openAccordion(e){
                e.target.previousSibling.previousSibling.click();
            },
            collapseOthers(index, tab){
                let i = 0;
                document.querySelectorAll('#'+ tab + "-accordion .header-main").forEach(el => {
                    if (i != index && el.getAttribute("aria-expanded") == "true"){
                        if(this.validateAccordion(i, tab, false)) el.click();
                    } 
                    i++;
                });
            },
            validateAccordion(i, tab, showValidation = true){
                let isValid = true;
                const collapser = showValidation ? document.getElementById(tab) : document.getElementById(tab  + "-collapse-" + i);
                let required = collapser.querySelectorAll("input, select, textarea");
                for(let field of required){
                    let value = field.value;
                    const emptySelect = this.$t('form.select');
                    if(field.dataset.content === "iban"){
                        const valid = this.checkIBAN(field);
                        if(!valid) isValid = false;
                    }
                    else if((field.required || field.classList.contains('required')) && (!value || value == emptySelect)){
                        if(showValidation) {
                            field.classList.add('is-invalid');
                            if(!collapser.classList.contains('show')){
                                collapser.previousElementSibling.children[0].click();
                            }
                        }
                        isValid = false;
                    }
                    else if(showValidation) {
                        field.classList.remove('is-invalid');
                    }
                }
                return isValid;
            },
            checkIBAN(field, index = null){
                const userIBAN = field.target ? field.target : field;
                let valid = userIBAN.value && IBAN.isValid(userIBAN.value);
                if(!valid) {
                    userIBAN.classList.add('is-invalid');
                }
                if(index !== null) this.invalidIBAN[index] = !valid;
                return valid;
            },
            getFilteredList(array, field){
                const filteredList = array.filter(item => item.id !== undefined);
                filteredList.sort((a, b) => a[field] > b[field] ? 1 : -1);
                return filteredList;
            },
            setOfficeNumbertwoDigits(e){
                if(e.target.value.length == 1){
                    this.finance.office_code_invoice_number = '0' + e.target.value;
                }
            },
            setCaret(e){
                this.caretStart = e.target.selectionStart;
                this.caretEnd = e.target.selectionEnd;
            },
            addToTemplate(e){
                const el = document.getElementById('inr-6');
                let newSelection = 0;
                if(this.caretStart != null && this.caretEnd != null){
                    newSelection = this.caretStart + e.target.innerHTML.length;
                    this.finance.invoice_email_template = this.finance.invoice_email_template.substring(0, this.caretStart) + e.target.innerHTML + this.finance.invoice_email_template.substring(this.caretEnd);
                }
                else{
                    this.finance.invoice_email_template += e.target.innerHTML;
                }
                this.$nextTick(() => {
                    el.focus()
                    el.setSelectionRange(newSelection, newSelection);
                });
            },

            removeLogo(e){
                this.finance.footer_logo_file_info = null;
                this.finance.footer_logo_file = null;
                this.finance.footer_logo_file_id = null;
            },
            download(file){
                fileService.download(file.id).then(response => {
                    this.triggerDownload(response.data, file.name);
                }).catch(error => {
                    this.toastError(error)
                })
            },

            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    this.setData(false);
                }
            }
        },
        mounted(){
            this.requiredOptions = [
                {label: this.$t('sales.build_year') + ' ' + this.$t('form.required'), model: 'construction_year_required'},
                {label: this.$t('navigation.colours') + ' ' + this.$t('form.required'), model: 'colour_required'},
                {label: this.$t('navigation.hs_codes') + ' ' + this.$t('form.required'), model: 'hs_code_required'},
                {label: this.$t('overview.license_plate_required'), model: 'license_plate_required'}
            ];
            this.savebutton = document.getElementById("savebutton");
            this.savebutton.disabled = true;
        }
    }
</script>
<style scoped>
    .accordion-item{margin-bottom: 1rem; border-top: 1px solid rgba(0,0,0,.125)}
    .acc-header-item { border: none }
    .accordion-header { width: 100%; }
    .accordion-button::after {
        background-image: none;
        transform: none
    }
    .acc-header-item.header-main {
        width: calc(100% - 100px);
        box-shadow: none !important;
        border-radius: 0px !important;
        transition: background-color .15s
    }
    .acc-header-item.header-remove-item, .acc-header-item.header-arrow {
        width: 50px;
        transition: background-color .15s
    }
    .accordion-button:not(.collapsed), .accordion-button:not(.collapsed) + .header-remove-item, .accordion-button:not(.collapsed) + * + .header-arrow {
        background-color: #0d6efd;
        color: white
    }
    .accordion-button.collapsed, .accordion-button.collapsed + .header-remove-item, .acc-header-item.collapsed + * + .header-arrow {
        background-color: white;
        color: #0d6efd;
        font-weight: bold;
    }
    .acc-header-item.header-arrow::after {
        display: block;
        content: '\276F'
    }
    .accordion-button:not(.collapsed) + * + .acc-header-item.header-arrow::after {
        rotate: 90deg
    }
    .header-main > div > div {
        text-overflow: ellipsis
    }
    .ps-33{
        padding-left: 35%
    }
    .nav-link:not(.active):not(.text-danger){
        color: #333 !important
    }
    #myTab > li > button{
        padding-left: .85rem;
        padding-right: .85rem;
    }
    #template-vars{
        color: #6c757d
    }
    #template-vars > span{
        color: #0d6efd;
        cursor: pointer
    }
</style>